"use strict";

document.addEventListener("DOMContentLoaded", function(){

const body = document.querySelector('body');

// MAIN NAV

const mainNavToggle = document.querySelector('.main-nav-toggle');
const mainNav = document.querySelector('.main-nav');
const subNavParents = document.querySelectorAll('.main-nav__list .parent');
const subNavs = document.querySelectorAll('.main-nav .sub-menu');
const header = document.querySelector('.pg-header');

mainNavToggle.addEventListener('click', function(){
  if(mainNav.classList.contains('is-visible')) {
    mainNav.classList.remove('is-visible');
    subNavParents.forEach(function(el){
      const subNav = el.querySelector('.sub-menu');
      subNav.classList.remove('is-visible');
    })
  } else {
    mainNav.classList.add('is-visible');
  }
});

subNavParents.forEach(function(el){
  const subNavToggle = el.querySelector('a');
  const subNav = el.querySelector('.sub-menu');
  
  subNavToggle.addEventListener('click', function(){
    let currentSubNav = false;

    if(subNav.classList.contains('is-visible')) {
      currentSubNav= true;
    }
    subNavs.forEach(function(el){
      el.classList.remove('is-visible');
      header.classList.remove('is-active');
    });

    if(!currentSubNav) {
      subNav.classList.add('is-visible');
      header.classList.add('is-active');
    }
  });
})

// STATS OVERFLOW

// const statsGrid = document.querySelector('.stats-grid-overflow');

// if(statsGrid) {

//   function resizeListener() {
//     let contentFeedSliderPos = statsGrid.getBoundingClientRect().left;
//     let contentFeedSliderOffsetWidth = window.innerWidth - contentFeedSliderPos;
//     statsGrid.style.width = contentFeedSliderOffsetWidth + "px";
//   }

//   window.addEventListener("resize", resizeListener);

//   resizeListener()

// }

const statsGridOverflow = document.querySelector('.stats-grid-overflow');
const statsGrid = document.querySelector('.stats-grid');

if (statsGridOverflow) {
  const statsGridWidth = statsGrid.offsetWidth;
  const mediaQuery = window.matchMedia(`(min-width: ${statsGridWidth}px)`);

  function handleMediaChange(e) {
    if (e.matches) {
      statsGrid.classList.add('is-centered');
    } else {
      statsGrid.classList.remove('is-centered');
    }
  }

  mediaQuery.addEventListener('change', handleMediaChange);
  handleMediaChange(mediaQuery); // Initial check
}


// FILTERS 

const filterGroupParents = document.querySelectorAll('.filter-group-outer');
const filterGroups = document.querySelectorAll('.filter-group');

if(filterGroupParents.length > 0) {

  filterGroupParents.forEach(function(el){
    const filterGroupToggle = el.querySelector('.filter-group-toggle');
    const filterGroup = el.querySelector('.filter-group');

    filterGroupToggle.addEventListener('click', function(){
      let currentDropdown = false;

      if(filterGroup.classList.contains('is-visible')) {
        currentDropdown= true;
      }
      filterGroups.forEach(function(el){
        el.classList.remove('is-visible');
      });

      if(!currentDropdown) {
        filterGroup.classList.add('is-visible');
      }
    });
    
  })

}

const filterGroupsWrapper = document.querySelector('.filter-groups');

function hideOnClickOutside(element, elementTwo) {
  // console.log(isVisible(element));

  const outsideClickListener = event => {
      if (!element.contains(event.target) && !elementTwo.contains(event.target)) {
        
        subNavs.forEach(function(el){
          el.classList.remove('is-visible');
        });

        filterGroups.forEach(function(el){
          el.classList.remove('is-visible');
        });
        
        // const activeLinks = document.querySelectorAll('.main-nav__list a');

        // activeLinks.forEach(function(el){
        //   el.classList.remove('is-active');
        // });
      }
  }

  document.addEventListener('click', outsideClickListener);
}

if(mainNav && filterGroupsWrapper) {
  hideOnClickOutside(mainNav, filterGroupsWrapper);
}

// TEAM INFO DRAWER

const teamMembers = document.querySelectorAll('.team-feed__item');
const drawers = document.querySelectorAll('.team-member-bio-drawer');
const closeButtons = document.querySelectorAll('.close-drawer');

if(teamMembers.length > 0) {
  
  teamMembers.forEach(button => {
    button.addEventListener('click', () => {
      const target = button.getAttribute('data-team-member');
      const targetDrawer = document.querySelector(`[data-team-bio="${target}"]`);
      const targetDrawerBio = targetDrawer.querySelector('.team-member-bio');
      targetDrawer.classList.add('is-visible');
      setTimeout(() => {
        body.classList.add('no-scroll');
        targetDrawerBio.classList.add('is-visible');
      }, 750);

    });
  });

  closeButtons.forEach(closeButton => {
    closeButton.addEventListener('click', () => {
      drawers.forEach(drawer => {
        const bio = drawer.querySelector('.team-member-bio');
        bio.classList.remove('is-visible')
        drawer.classList.remove('is-visible');
      });
      body.classList.remove('no-scroll');
    });
  });

}

// INTERSECTION OBSERVER SETUP

function observeElementWithClass({
  targetSelector,
  threshold = 0.1,
  className = 'in-viewport'
}) {
  // Select the target element
  const targetElement = document.querySelector(targetSelector);
  if (!targetElement) {
    console.error(`Element not found: ${targetSelector}`);
    return;
  }

  // Callback for Intersection Observer
  const handleIntersection = (entries) => {
    const entry = entries[0]; // Only one element is observed
    if (entry.isIntersecting) {
      targetElement.classList.add(className);
    } else {
      targetElement.classList.remove(className);
    }
  };

  // Create the Intersection Observer
  const observer = new IntersectionObserver(handleIntersection, {
    root: null, // Use the viewport as the root
    threshold: threshold
  });

  // Start observing the target element
  observer.observe(targetElement);

  // Return the observer instance in case you need to disconnect it
  return observer;
}

const homeContent = document.querySelector('.home-content');

if(homeContent) {

observeElementWithClass({
  targetSelector: '.home-content',
  threshold: 0.5, // 50% visibility required
  className: 'is-visible'
});

}

const elementsToAnimate = document.querySelectorAll('.will-animate');

if (elementsToAnimate.length > 0) {
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('in-viewport');
        observer.unobserve(entry.target); // Stop observing once the class is added
      }
    });
  }, {
    threshold: 0.1 // Adjust this to determine how much of the element must be visible to trigger
  });

  elementsToAnimate.forEach(element => {
    // Check if the element is already in the viewport
    const rect = element.getBoundingClientRect();
    const inViewport = rect.top < window.innerHeight && rect.bottom > 0;

    if (inViewport) {
      element.classList.add('in-viewport'); // Add class immediately if in viewport
    } else {
      observer.observe(element); // Otherwise, observe for visibility changes
    }
  });
}


const investmentsFeed = document.querySelector('.investments-feed');

if(investmentsFeed) {
  const mixer = mixitup(investmentsFeed, {
    animation: {
      enable: false,
      animateResizeContainer: false
    },
    multifilter: {
      enable: true
    },
    callbacks: {
      onMixEnd: function(state, futureState) {
        let activeFilters = [];
        let filterToggles = document.querySelectorAll('.filter-group-toggle');

        futureState.filterGroups.forEach((filter, index) => {
          filter.activeToggles.forEach(active => {
            activeFilters.push(document.querySelector(`[data-toggle="${active}"]`).innerHTML);
          });

          let toggleButton = filterToggles[index + 1].querySelector('button .count');
          if(filter.activeToggles.length > 0) {
            toggleButton.innerHTML = '(' + filter.activeToggles.length + ')';
          } else {
            toggleButton.innerHTML = '';
          }

        });

        if(activeFilters.length > 0) {
          document.getElementById('active-filters').innerHTML = 'Filtered by: ' + activeFilters.join(', ');
        } else {
          document.getElementById('active-filters').innerHTML = '';
        }
      }
    }
  });
}

const video = document.querySelector('.home-hero-video');

    if (!video) return; // Exit if the video element is not found

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                video.play();
            } else {
                video.pause();
            }
        });
    }, {
        threshold: 0.5 // Adjust this to define how much of the video must be visible to trigger
    });

    observer.observe(video);



// END

});